




























import { Component, Mixins } from 'vue-property-decorator';
import CarColorPicker from '@/modules/cars/components/car-color-picker.vue';
import CarCalendar from '@/modules/cars/components/car-calendar.vue';
import CarActions from '@/modules/cars/components/car-actions.vue';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import CarHeader from '@/modules/cars/components/car-header.vue';
import CarRatesTooltip from '@/modules/cars/components/car-rates-tooltip.vue';
import CarTogglePrices from '@/modules/cars/components/car-toggle-prices.vue';
import CarsSwitcher from '@/modules/cars/components/cars-switcher.vue';
import CarRatesPageOtelLogs from '@/modules/cars/modules/open-telemetry/mixins/car-rates-page-otel-logs.mixin';

@Component({
    components: {
        CarColorPicker,
        CarCalendar,
        CarActions,
        PageWrapper,
        CarHeader,
        CarRatesTooltip,
        CarTogglePrices,
        CarsSwitcher,
    },
})
export default class RatesCalendarPage extends Mixins(CarRatesPageOtelLogs) {
    get isVans(): boolean {
        return this.carsSharedService.carsStoreState.settings.isVans;
    }

    public onClick(value: boolean) {
        this.carsSharedService.carsStoreState.settings.isVans = value;
    }
}
